<script setup lang="ts">
const {
  size = "base",
  name = "Avatar Name",
  loading = false,
} = defineProps<{
  name?: string;
  image?: string;
  type: "individual" | "company" | "team";
  size?: keyof typeof sizes;
  loading?: boolean;
}>();

const sizes = {
  xs: {
    class: "h-[24px] w-[24px] min-w-[24px]",
    spinner: "h-[14px] w-[14px]",
  },
  sm: {
    class: "h-[34px] w-[34px] min-w-[34px]",
    spinner: "h-[20px] w-[20px]",
  },
  base: {
    class: "h-[40px] w-[40px] min-w-[40px]",
    spinner: "h-[24px] w-[24px]",
  },
  lg: {
    class: "h-[48px] w-[48px] min-w-[48px]",
    spinner: "h-[28px] w-[28px]",
  },
  upload: {
    class: "h-[160px] w-[160px] min-w-[160px]",
    spinner: "h-[32px] w-[32px]",
  },
};

const nameArray = computed(() => {
  if (!name) return [];

  const splittedName = name.trim().split(" ");

  return splittedName;
});
</script>

<template>
  <div
    class="relative aspect-square overflow-hidden rounded-full"
    :class="[sizes[size].class]"
  >
    <img
      v-if="image"
      :src="image"
      class="h-full w-full object-cover object-center"
    />
    <!--
    <img
      v-else-if="type === 'team'"
      src="/avatar/team.png"
      class="h-full w-full object-cover object-center"
    />
    <img
      v-else-if="type === 'company'"
      src="/avatar/company.png"
      class="h-full w-full object-cover object-center"
    />
    -->
    <div
      v-else
      class="flex h-full w-full items-center justify-center bg-gray-100 text-sm font-bold text-gray-500"
    >
      {{ nameArray[0].charAt(0) }} {{ nameArray[1].charAt(0) }}
    </div>
    <div
      v-if="loading"
      class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/70"
    >
      <RiseSpinner
        :class="[sizes[size].spinner]"
        class="fill-white text-white/60"
      />
    </div>
  </div>
</template>
